:global(.liveby-embed #market-trends) {
  .liveby-statistic.expanded {
    position: absolute;
    width: 100%;
    z-index: 10;
    top: 0;
    right: 0;
    left: 0;
    padding: 20px;
    max-width: 93%;
    max-width: calc(100% - 65px);
  }
  .statistic-toggle-minimized {
    pointer-events: none;
  }

  .liveby-statistic {
    background-color: #fff;
    color: #000;
    border-radius: 3px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
    box-sizing: content-box;
    padding: 12px;
    margin: 0 12px 24px;
    display: flex;
    flex-flow: column;
    width: 285px;
    min-height: 225px;
    position: relative;
    transition:
      transform 400ms,
      box-shadow 300ms;

    .statistic-title {
      color: #000;
      font-size: 1em;
      margin: 0 0 16px;
      max-width: 95%;
    }

    .statistic-toggle {
      cursor: pointer;
      position: absolute;
      padding: 10px;
      top: 0;
      right: 0;
      transition: transform 300ms;
    }

    .statistic-toggle-expanded {
      padding: 20px;
    }

    .statistic-content {
      display: block;
      height: auto;
    }

    .expanded-stat {
      width: 100%;
      height: 100%;
    }

    :global .recharts-default-tooltip {
      border: unset !important;
      border-radius: 3px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    }
  }

  .expandable:hover:not(.expanded) {
    box-shadow: 0 2px 7px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transform: scale(1.025);
  }
}

:global(.liveby-embed .container-query--max-width-700 #market-trends) {
  .liveby-statistic {
    max-width: none;
    min-width: 0;
    min-height: 0;
    width: calc(100% - 48px);

    .statistic-title {
      font-size: 0.875em;
      margin: 0 0 0.75em;
    }

    .statistic-content {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      flex: 1 1 auto;
    }
  }

  .liveby-statistic.expanded {
    height: auto;
    padding: 10px;
    max-width: none;
    position: relative;
    transform: scale(1.025);
    .statistic-toggle-expanded {
      padding: 10px;
    }
  }
}

:global(.liveby-embed .container-query--max-width-500 #market-trends) {
  .liveby-statistic {
    min-height: 0;
  }
  .liveby-statistic.expanded {
    position: relative;
  }
}
