:global(.liveby-embed .recharts-tooltip-wrapper) {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  padding: 0.75em 0.75em 0.5em;
  border-radius: 3px;
  font-size: 12px;
  color: #7e7e7e;
}

:global(.liveby-embed .recharts-legend-wrapper) {
  top: -66px !important;
  right: 430px !important;
  left: auto !important;
}

.tooltip {
  padding: 5px 10px;
}

.expandedTooltip {
  list-style-type: none;
  padding: 10px 0 0;
  margin: 0;

  li {
    margin: 0 10px 5px;
  }

  li:nth-of-type(3) {
    margin-bottom: 10px;
  }

  .incomplete-item {
    margin: 0;
  }
}

.tooltip-key {
  background-color: #f9f9f9;
  display: block;
  font-size: 1.1666666667em;
  font-weight: 600;
  margin: 0;
  padding: 0.25em 0;
  text-align: center;
}

.tooltipLabel {
  display: inline-block;
  width: 85px;
}

.tooltipValue {
  margin-left: 5px;
}

.last-tooltip {
  border-radius: 3px;
  text-align: center;
  padding-top: 15px;
}

.incomplete {
  background-color: #f9f9f9;
  font-size: 10px;
  padding: 5px 10px;
  margin: 10px 0 0;
  text-align: center;
}

.expanded-chart-legend {
  list-style-type: none;
  color: #93999b;
  font-size: 12px;
  display: inline-block;
}

.expanded-chart-legend-label {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expanded-chart-legend-line {
  margin-left: 10px;
}

.mobile-chart-title {
  font-size: 0.75em;
  margin: 0 auto 0.5em;
  font-weight: 300;
}

:global(.liveby-embed .container-query--max-width-800) {
  :global .recharts-legend-wrapper {
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
  }

  .expanded-chart-legend {
    display: flex;
    padding: 0;
    margin-bottom: 1.5em;
    justify-content: center;
  }

  .expanded-chart-legend-label {
    margin-bottom: unset;
    margin-right: 24px;
    font-size: 0.6666666667em;
  }
}

:global(.liveby-embed .container-query--max-width-500) {
  .expanded-chart-legend {
    display: block;
    padding: 0;
    margin: 0 auto;
    max-width: 10em;
  }

  .expanded-chart-legend-label {
    margin-bottom: unset;
    margin-right: 24px;
    font-size: 0.6666666667em;
  }
}
